
































import Vue from 'vue';
import ResponsiveDataMixin from '@/components/mixins/ResponsiveDataMixin.vue';
import ApiService from '@/common/api.service';
import { endpoints } from '@/common/config';
import { processResponseError } from '@/common/misc';
import { phoneNumberRegex } from '@/common/misc'

const initialData = {
    isSubmitted: false,
    isFetching: false,
    requesterName: '',
    requesterPhoneNumber: '',
    message: '',
};

// TODO: (?) Require captcha, store & send form data to server
export default Vue.extend({
    name: 'landingContactAuthorModal',
    mixins: [ResponsiveDataMixin],
    data(){
        return Object.assign({}, initialData);
    },
    methods: {
        async onSubmit():Promise<void> {
            if(this.isFetching) return;
            
            this.isFetching = true;
            try {
                const {requesterName, requesterPhoneNumber, message} = this;
                await ApiService.post(endpoints.postLandingContactAuthorRequest, {requesterName, requesterPhoneNumber, message});
                this.isSubmitted = true;
            } catch (error) {
                console.error(error);
                alert("При отправке запроса произошла ошибка.\n"+processResponseError(error));
            } finally {
                this.isFetching = false;
            }
        },
        resetData():void { 
            Object.assign(this.$data, initialData);
        }
    },
    computed: {
        phoneNumberRegex: () => phoneNumberRegex,
    }
})
