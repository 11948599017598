









import Vue from 'vue'
export default Vue.extend({
    name: 'landingOrderBulkModal',
    methods: {
        onSubmit():void{
            //
        },
    },
})
